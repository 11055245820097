// import "./ReservationModal.scss";
//import "./ReservationModal.css";
import { useState, useEffect } from "react";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import Moment from 'react-moment';
// import 'moment-timezone';
//import moment from "moment";
import "../Style/KompaniModal.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const KompaniModal = (props) => {
  const token = localStorage.getItem("token");


  const [openModal, setOpenModal] = useState(true);
  const [neto, setNeto] = useState(props.data.neto); // This is the value that will be updated based on the "Likujduar" value
  const [likujduar, setLikujduar] = useState(props.data.likujduar);

  useEffect(() => {
    // Update the "Neto" value based on the "Likujduar" value
    if (likujduar === "Po") {
      setNeto(0);
    } else {
      setNeto(neto);
    }
  }, [likujduar, props.data.neto,neto]);


  const handleEditReservation = async (e) => {
    console.log("Reservimi", e);
    e.preventDefault();

    // const formattedDepartureDate = new Date(props.data.departure_date).toLocaleDateString('en-US');
// const formattedArrivalDate = new Date(props.data.arrival_date).toLocaleDateString('en-US');

const formattedDateBooked = new Date(props.data.date_booked).toISOString().split("T")[0];
    const formattedDepartureDate = new Date(props.data.departure_date).toISOString().split("T")[0];
    const formattedArrivalDate = new Date(props.data.arrival_date) ? new Date(props.data.arrival_date).toISOString().split("T")[0] : null;
    // console.log("neto", neto)

    const editReservationData = {
        // date_booked: e.target.date_booked.value,
        date_booked: formattedDateBooked,
        series: e.target.series.value,
        departure_time: e.target.departure_time.value,
        agjenti: e.target.agjenti.value,
        phone_no: e.target.phone_no.value,
        client_name: e.target.client_name.value,
        client_surname: e.target.client_surname.value,
        // departure_date: e.target.departure_date.value,
        departure_date: formattedDepartureDate,
        // arrival_date: e.target.arrival_date.value,
        arrival_date: formattedArrivalDate,
        departure_location: e.target.departure_location.value,
        arrival_location: e.target.arrival_location.value,
        price: e.target.price.value,
        tsc: e.target.tsc.value,
        profit: e.target.profit.value,
        neto: neto,
        likujduar: likujduar,
        company_name: e.target.company_name.value,
        id: props.data.id,
    };

    props.updateTicket(editReservationData);
    // console.log("dataaaa", props.data.pickupDate);
    console.log("formattedDateBooked:", formattedDateBooked);
console.log("formattedDepartureDate:", formattedDepartureDate);
console.log("formattedArrivalDate:", formattedArrivalDate);

try {
  const response = await axios.put(
    `http://localhost:3001/addEntry/update/` + props.data.id,
    editReservationData, {
      headers: {
        Authorization: `Bearer ${token}`,
    }})
    console.log("data", response.data);
    setOpenModal(false)
} catch (err) {
  console.log(err);
}
  }



  //blabla
  if (openModal === true) {
    return (
      <div className="modali">
        <div className="modal-contenti ps-2 pe-2">
          <div className="mx-auto d-flex flex-row me-4 ms-3">
            <h2 className="mt-2 text-left mr-auto p-2 col-8 title">
              Update your reservation
            </h2>
            <h2
              className="close mt-2 p-2 col-4 text-end"
              onClick={() => setOpenModal(false)}
            >
              X
            </h2>
          </div>
          <form className="needs-validation mt-3 ps-4 pe-4" onSubmit={handleEditReservation}>
            <div className="row g-3">
              <div className="col-lg-3 col-md-12">
                <label for="dateBooked" className="form-label">
                  Data e prerjes
                </label>
                {console.log("date", props.data.date_booked)}
                {/* <DatePicker
                  className="form-control"
                  selected={new Date(props.data.date_booked)}
                  placeholderText="MM/DD/YYYY"
                  showIcon
                  name="date_booked"
                  
                /> */}
                <DatePicker
                    className="form-control"
                    selected={new Date(props.data.date_booked)}
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    selectsStart
                    startDate={new Date(props.data.date_booked)}
                    name="date_booked"
                  />
              </div>
              <div className="col-lg-3 col-md-12">
                <label for="seria" className="form-label">
                  Seria
                </label>
                {/* {console.log("name", props.data.name)} */}
                <input
                  defaultValue={props.data.series}
                  type="text"
                  className="form-control"
                  id="seria"
                  name="series"
                  // onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-lg-3 col-md-12">
                <label for="time" className="form-label">
                  Ora Nisjes
                </label>
                {/* {console.log("name", props.data.name)} */}
                <input
                  defaultValue={props.data.departure_time}
                  type="text"
                  className="form-control"
                  id="time"
                  name="departure_time"
                  // onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="col-lg-3 col-md-12">
                <label for="agjenti" className="form-label">
                  Agjenti
                </label>
                <input
                  defaultValue={props.data.agjenti}
                  type="text"
                  className="form-control"
                  placeholder="agjenti"
                  id="agjenti"
                  name="agjenti"
                  // onChange={(e) => setSurname(e.target.value)}
                />
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 col-md-12">
                  <label for="client_name" className="form-label">
                    Emri
                  </label>
                  <input
                    defaultValue={props.data.client_name}
                    type="text"
                    className="form-control"
                    placeholder="client_name"
                    id="client_name"
                    name="client_name"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="client_surname" className="form-label">
                    Mbiemer
                  </label>
                  <input
                    defaultValue={props.data.client_surname}
                    type="text"
                    className="form-control"
                    placeholder="client_surname"
                    id="client_surname"
                    name="client_surname"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="phone_no" className="form-label">
                    Nr Cel
                  </label>
                  <input
                    defaultValue={props.data.phone_no}
                    type="text"
                    className="form-control"
                    placeholder="phone_no"
                    id="phone_no"
                    name="phone_no"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="company_name" className="form-label">
                    Kompania
                  </label>
                  <input
                    defaultValue={props.data.company_name}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="company_name"
                    id="company_name"
                    name="company_name"
                  />
                </div>
              </div>
              <div className="row mt-3">
              <div className="col-lg-3 col-md-12">
                  <label for="departure_location" className="form-label">
                    Nisja
                  </label>
                  <input
                    defaultValue={props.data.departure_location}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="departure_location"
                    id="departure_location"
                    name="departure_location"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="arrival_location" className="form-label">
                    Arrita
                  </label>
                  <input
                    defaultValue={props.data.arrival_location}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="arrival_location"
                    id="arrival_location"
                    name="arrival_location"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6 col-md-12">
                  <label for="nisja" className="form-label">
                    Data Nisjes
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(props.data.departure_date)}
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    selectsStart
                    startDate={new Date(props.data.departure_date)}
                    name="departure_date"
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <label for="kthimi" className="form-label">
                    Data Kthimit
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={new Date(props.data.arrival_date)}
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    selectsStart
                    startDate={new Date(props.data.departure_date)}
                    name="arrival_date"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-3 col-md-12">
                  <label for="price" className="form-label">
                    Cmimi
                  </label>
                  <input
                    defaultValue={props.data.price}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="price"
                    id="price"
                    name="price"
                  />
                </div>

                <div className="col-lg-3 col-md-12">
                  <label for="tsc" className="form-label">
                    Tsc
                  </label>
                  <input
                    defaultValue={props.data.tsc}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="tsc"
                    id="tsc"
                    name="tsc"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="profit" className="form-label">
                    Profit
                  </label>
                  <input
                    defaultValue={props.data.profit}
                    type="text"
                    readOnly
                    className="form-control"
                    placeholder="profit"
                    id="profit"
                    name="profit"
                  />
                </div>
                <div className="col-lg-3 col-md-12">
                  <label for="neto" className="form-label">
                    Neto
                  </label>
                  {likujduar === "Po" ? (
                    <input
                    defaultValue={neto}
                    type="text"
                    // readOnly
                    className="form-control"
                    placeholder="neto"
                    id="neto"
                    name="neto"
                    readOnly
                  />
                  ) : (

                  <input
                    defaultValue={neto}
                    type="text"
                    // readOnly
                    className="form-control"
                    placeholder="neto"
                    id="neto"
                    name="neto"
                    onChange={(e)=>setNeto(e.target.value)}
                  />
                  )}
                </div>

                <div className="row mt-3">
                  <div className="col-lg-3 col-md-12">
                    <label for="likujdimi" className="form-label">
                        Likujduar
                    </label>
                    <select
          className="form-select"
          name="likujduar"
          id="likujdimi"
          value={likujduar}
          onChange={(e) => {
            // Update the local state for likujduar when the select value changes
            setLikujduar(e.target.value);
          }}
        >
          <option value="Po">Po</option>
          <option value="Jo">Jo</option>
        </select>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center">
                <button className="btn btn-lg btn-warning mt-2 mb-4" type="submit">
                  Update Reservation
                </button>
                {/* <ToastContainer /> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else return null;
};

export default KompaniModal;
