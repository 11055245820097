import React from 'react'
import Booking from '../Components/Home/Booking'
import Navbar from '../Shared/Navbar'
import Footer from '../Shared/Footer'
import "./Styles/HomePage.scss"

const HomePage = () => {
  return (
    <>
        <div className="home-page">
          <Navbar/>
        <Booking/>
        <Footer/>
        </div>
    </>
  )
}

export default HomePage