import React from 'react'
import Navbar from '../Shared/Navbar'
import RegisterForm from '../Components/Authentication/RegisterForm'

const RegisterPage = () => {
  return (
    <div>
        {/* <Navbar/> */}
        <RegisterForm/>
    </div>
  )
}

export default RegisterPage