import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import AgjentetModal from "./AgjentetModal";
import { render } from "@testing-library/react";
import "../Style/Pagination.scss";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useRef } from "react";

const Agjentet = () => {
  const token = localStorage.getItem("token");

  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [likujduarPara, setLikujduarPara] = useState("");

  const [agents, setAgents] = useState([]);

  const [allTickets, setAllTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const [likujduarStates, setLikujduarStates] = useState([]);
  useEffect(() => {
    // Initialize likujduarStates with initial values from filteredTickets
    const initialStates = filteredTickets.map((ticket) => ticket.likujduar);
    setLikujduarStates(initialStates);
  }, [filteredTickets]);

  const getAgents = async () => {
    try {
      const response = await axios.get("http://localhost:3001/user/showUsers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAgents(response.data);
      console.log("agents", response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getTickets = async () => {
    try {
      const response = await axios.get(
        "http://localhost:3001/bileta/getBileta",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllTickets(response.data);
      console.log("alltickets", response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTickets();
    getAgents();
  }, []);

  const handleCompanyClick = (username) => {
    setSelectedAgent(username);
    console.log("chosen agent", username);
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      const filteredData = allTickets.filter(
        (ticket) => ticket.agjenti.toLowerCase() === selectedAgent.toLowerCase()
      );
      setFilteredTickets(filteredData);
      console.log("filtered", filteredTickets);
    } else {
      const filteredData = allTickets.filter((ticket) => {
        const ticketDate = new Date(ticket.departure_date);
        return (
          ticket.agjenti.toLowerCase() === selectedAgent.toLowerCase() &&
          ticketDate >= startDate &&
          ticketDate <= endDate
        );
      });
      setFilteredTickets(filteredData);
    }
  }, [allTickets, selectedAgent, startDate, endDate]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `http://localhost:3001/bileta/deleteBileta/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("deleted", response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTicket = (updatedTicket) => {
    // Find the index of the updated ticket in the filteredTickets array
    const index = filteredTickets.findIndex(
      (ticket) => ticket.id === updatedTicket.id
    );

    // Create a copy of the filteredTickets array and update the specific ticket
    const updatedFilteredTickets = [...filteredTickets];
    updatedFilteredTickets[index] = updatedTicket;

    // Update the filteredTickets state with the updated array
    setFilteredTickets(updatedFilteredTickets);
  };

  const getTotalSum = () => {
    return filteredTickets.reduce((total, ticket) => total + ticket.price, 0);
  };

  const getProfitSum = () => {
    return filteredTickets.reduce((total, ticket) => total + ticket.profit, 0);
  };

  const getNetoSum = () => {
    return filteredTickets.reduce((total, ticket) => total + ticket.neto, 0);
  };

  const ITEMS_PER_PAGE = 20;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const displayedTickets = filteredTickets.slice(startIndex, endIndex);

  // table ref - with library
  const TableRef = useRef(null);

  // export table without library
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredTickets);
    const wb = { Sheets: { reservations: ws }, SheetNames: [`reservations`] };
    const ExcelBuffer = XLSX.write(wb, { bookType: `xlsx`, type: `array` });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Reservation List` + fileExtension);
  };

  return (
    <>
      <div className="agjentet ms-3">
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
          {agents.map((el, index) => {
            return (
              <li class="nav-item" role="presentation" key={index}>
                <button
                  class={`nav-link ${
                    el.username === selectedAgent ? "active" : ""
                  }`}
                  id="anila-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#anila"
                  type="button"
                  role="tab"
                  aria-controls="anila"
                  aria-selected="true"
                  onClick={() => handleCompanyClick(el.username)}
                >
                  {el.username}
                </button>
              </li>
            );
          })}
          {/* <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="anila-tab"
              data-bs-toggle="tab"
              data-bs-target="#anila"
              type="button"
              role="tab"
              aria-controls="anila"
              aria-selected="true"
            >
              Anila
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Profile
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Contact
            </button>
          </li> */}
        </ul>
        <div class="tab-content" style={{ width: "90%" }} id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="anila"
            role="tabpanel"
            aria-labelledby="anila-tab"
          >
            {filteredTickets && filteredTickets.length > 0 ? (
              <div>
                <div className="row d-flex justify-content-center mb-3">
              <div className="col-4 d-flex justify-content-center">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Nga"
                />
              </div>
              <div className="col-4 d-flex justify-content-center">
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Deri"
                />
              </div>
            </div>
              <div className="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Seria</th>
                    <th scope="col">Data Prerjes</th>
                    <th scope="col">Emri</th>
                    <th scope="col">Mbiemri</th>
                    <th scope="col">Kompania</th>
                    <th scope="col">Nisja</th>
                    <th scope="col">Destinacioni</th>
                    <th scope="col">Nisja</th>
                    <th scope="col">Kthimit</th>
                    <th scope="col">Ora e nisjes</th>
                    <th scope="col">Nr Tel</th>
                    <th scope="col">Cmimi</th>
                    <th scope="col">tsc</th>
                    <th scope="col">Neto</th>
                    <th scope="col">Profit</th>
                    {/* <th scope="col">Agjenti</th> */}
                  </tr>
                </thead>
                <tbody>
                  {displayedTickets.map((el, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          likujduarStates[index] === "Po" ? "green" : "white",
                        color:
                          likujduarStates[index] === "Po" ? "white" : "black",
                      }}
                    >
                      {/* Add your table data cells here based on the filtered data */}
                      <td>
                        <button
                          className="btn btn-warning"
                          onClick={() =>
                            render(
                              <AgjentetModal
                                data={el}
                                updateTicket={updateTicket}
                              />
                            )
                          }
                        >
                          Edito
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(el.id)}
                        >
                          Fshi
                        </button>
                      </td>
                      <td>{el.series}</td>
                      <td> {new Date(el.date_booked).toLocaleDateString(
                            "en-GB"
                          )}</td>
                      <td>{el.client_name}</td>
                      <td>{el.client_surname}</td>
                      <td>{el.company_name}</td>
                      <td> {el.departure_location} </td>
                      <td> {el.arrival_location} </td>
                      <td>
                        {new Date(el.departure_date).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td>
                        {el.arrival_date
                          ? new Date(el.arrival_date).toLocaleDateString()
                          : ""}
                      </td>
                      <td> {el.departure_time} </td>
                      <td> {el.phone_no} </td>
                      <td> {el.price} </td>
                      <td> {el.tsc} </td>
                      <td> {el.neto} </td>
                      <td> {el.profit} </td>
                      {/* Add other table cells here based on your data */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span>Page {currentPage}</span>
              <button
                disabled={
                  currentPage ===
                  Math.ceil(filteredTickets.length / ITEMS_PER_PAGE)
                }
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
            <div className="row col-6 mt-5">
            <div className="col-md-3">
              <p> Total: {Number(getTotalSum())} </p>
            </div>
            <div className="col-md-3">
              <p> Profit: {Number(getProfitSum())} </p>
            </div>
            <div className="col-md-3">
              <p> Neto: {Number(getNetoSum())} </p>
            </div>
          </div>
          <div>
            <button className="btn btn-success mb-5" onClick={exportToExcel}>
              Export
            </button>
          </div>
              </div>
            ) : (
              <div>
                <p className="text-danger text-center">Zgjidhni nje agjent per te pare biletat!</p>
              </div>
            )}
            
          
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Agjentet;
